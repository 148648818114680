import React from 'react';

import Layout from "../layouts/site/layout";

function Values() {
  return (
    <div className="App">
       <Layout>
           <div className="px-4 pt-6 pb-10 ">
                <h2 className="text-red-1000 font-bold text-base mt-2">Core values</h2>
                <p className="mt-2">Health and safety of our employees is our highest priority.</p>
                <p className="mt-2">People are our greatest asset.</p>
                <p className="mt-2">Honesty, trust and integrity are the foundation of our business.</p>
                <p className="mt-2">We are a market-led customer focused organisation.</p>
                <p className="mt-2">We will achieve our mission by maintaining a commitment to continuous improvement through an integrated system involving all employees, processes and products.</p>
                <p className="mt-2">We recognise that MSI-Quality Forgings must positively address and embrace change.</p>
                <h3 className="text-[15px] text-center font-bold mt-3">Forging Solutions Transforming Business</h3>
            </div>  
       </Layout>
    </div>
  );
}

export default Values;
